import Network from '@/services/network';

export default function handleProjects() {
    const network = new Network;

    const fetchBusinesses = (query) => {
        return network.api('get', '/business' + query);
    }

    const fetchParties = (query) => {
        return network.api('get', '/contact-profile-names' + query);
    }

    const fetchRoles = (query) => {
        return network.api('get', '/roles' + query);
    }

    const createNewProject = (query, data) => {
        return network.api('post', `/procurement/project${query}`, data);
    }

    const fetchProjectList = (query) => {
        return network.api('get', '/procurement/project' + query);
    }

    const fetchProjectTrackerList = (query) => {
        return network.api('get', '/procurement/project/projet-tracker-list' + query);
    }

    const fetchProjects = (query) => {
        return network.api('get', '/procurement/project/get' + query);
    }

    const fetchProjectForm = (id, query) => {
        return network.api('get', `/procurement/project/${id}/edit${query}`);
    }

    const createProjectAccountGroup = (query, data) => {
        return network.api('post', '/procurement/project/account/group' + query, data);
    }

    const createProjectAccount = (query, data) => {
        return network.api('post', '/procurement/project/account' + query, data);
    }

    const updateProjectAccountGroup = (id, query, data) => {
        return network.api('patch', '/procurement/project/account/group/' + id + query, data);
    }

    const updateProjectAccount = (id, query, data) => {
        return network.api('patch', '/procurement/project/account/' + id + query, data);
    }

    const fetchProjectAccount = (id, query) => {
        return network.api('get', `/procurement/project/account/${id + query}`);
    }

    const fetchProjectAccountTree = (id, query) => {
        return network.api('get', `/procurement/project/${id}/account${query}`);
    }

    const fetchProjectAccounts = (id, query) => {
        return network.api('get', `/procurement/project/${id}/account/all${query}`);
    }

    const fetchProjectGroups = (id, query) => {
        return network.api('get', `/procurement/project/${id}/account/groups${query}&is_flatten=1`);
    }

    const fetchProjectDonors = (id, query) => {
        return network.api('get', `/procurement/project/${id}/get/donors${query}`);
    }

    const fetchDonorProjectAccounts = (id, query) => {
        return network.api('get', `/procurement/budget/${id}/activities${query}`);
    }

    const fetchDonorBudgets = (id, query) => {
        return network.api('get', `/procurement/budget/donor/${id}/get-budget${query}`);
    }

    const fetchActualCost = (id, query) => {
        return network.api('get', `/procurement/budget/project-account/${id}/cost${query}`);
    }

    const updateProject = (id, query, data) => {
        return network.api('put', `/procurement/project/${id}` + query, data);
    }

    const fetchAccountsAndItemsByProjectId = (id, query) => {
        return network.api('get', `/procurement/project/accounts-and-items-by-project-id/${id}${query}`);
    }

    const fetchProjectsWithSearchPaginate = (query) => {
        return network.api('get', '/procurement/project/list' + query);
    }

    const fetchProjectOverview = (id, query) => {
        return network.api('get', `/procurement/project/register/overview/${id}${query}`);
    }

    return {
        fetchBusinesses,
        fetchParties,
        fetchRoles,
        createNewProject,
        fetchProjectList,
        fetchProjectTrackerList,
        fetchProjects,
        createProjectAccountGroup,
        createProjectAccount,
        updateProjectAccountGroup,
        updateProjectAccount,
        fetchProjectForm,
        fetchProjectAccountTree,
        fetchProjectAccounts,
        fetchProjectAccount,
        fetchProjectGroups,
        updateProject,
        fetchProjectDonors,
        fetchDonorProjectAccounts,
        fetchActualCost,
        fetchDonorBudgets,
        fetchAccountsAndItemsByProjectId,
        fetchProjectsWithSearchPaginate,
        fetchProjectOverview
    }
}
